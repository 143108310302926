import { fetcher } from '../fetcher';

const FORMS_ENDPOINT = '/forms';

export const formsService = {
  storeForm: async (payload: FormForm) => {
    return await fetcher(`${FORMS_ENDPOINT}`, {
      method: 'POST',
      body: payload,
      schema: formSchema,
    });
  },
  getForm: async (form_id: number) => {
    return await fetcher(`${FORMS_ENDPOINT}/${form_id}`, {
      method: 'GET',
      schema: formSchema,
    });
  },
  getFormsAll: async () => {
    return await fetcher(`${FORMS_ENDPOINT}?per_page=100000`, {
      method: 'GET',
      schema: formMetaSchema,
      meta: true,
    });
  },
  getForms: async ({
    active = true,
    search,
    page,
    per_page = 10,
  }: {
    active: boolean;
    search: string;
    page: number;
    per_page?: number;
  }) => {
    const endpoint = `${FORMS_ENDPOINT}?search=${search}&page=${page + 1}&per_page=${per_page}&is_active=${active}`;

    return await fetcher(endpoint, {
      method: 'GET',
      schema: formMetaSchema,
      meta: true,
    });
  },
  deleteForm: async (form_id: number) => {
    return await fetcher(`${FORMS_ENDPOINT}/${form_id}`, {
      method: 'DELETE',
    });
  },
  editForm: async (payload: FormForm, form_id: number) => {
    return await fetcher(`${FORMS_ENDPOINT}/${form_id}`, {
      method: 'PATCH',
      body: payload,
      schema: formSchema,
    });
  },
  enableFormUser: async (form_user_id: number) => {
    return await fetcher(`/form-users/${form_user_id}/enable`, {
      method: 'POST',
    });
  },
};
